<template>
  <div class="card">
    <div class="card-header header_options">
      <div class="card-title header_title">OPT Outs</div>
      <div class="col d-flex">
        <div class="form-group col pl-0 mb-0">
          <div class="input-group">
            <input
              placeholder=""
              type="text"
              class="form-control"
            />
            <div class="input-group-prepend">
              <button type="button" class="btn icon_btn m-0">
                <span class="material-symbols-outlined"> search </span>
              </button>
            </div>
          </div>
        </div>
        <div class="opts_card">
          <!-- <span class="material-icons add_person"> person_add </span>
          <span class="material-icons-outlined title"> file_upload </span>
          <i class="fas fa-file-export title"></i> -->
          <button class="btn"
              :class="{'qt-loader qt-loader-mini qt-loader-right': isSending,}"
              @click="deleteMultiple"
              v-bind:disabled="selectedOpted.length == 0 || isSending">
              <span class="material-symbols-outlined title">delete</span>
          </button>
        </div>
      </div>
      <b-dropdown offset="-610" variant="yup-purple" class="keyword-dropdown">
        <template #button-content>
          <span class="material-symbols-outlined">add</span>
        </template>
        <div class="container">
          <p class="keyword-title">Configurações OPT-Out</p>
          <div class="row">
            <div class="col p-0">
              <keywords-card ref="keywords" />
            </div>
          </div>
        </div>
      </b-dropdown>
    </div>
    <div v-if="!optouts.length && fetched" class="card-body">
      <LottieAnimNoData />
      <h5 class="card-title text-center">
        {{ $t('warnings.optouts.none') }}
      </h5>
      <!-- Nenhum deny list cadastrado -->
      <p class="text-center">{{$t('warnings.optouts.register')}}</p>
    </div>
    <div class="card-body" v-if="fetched">
      <div class="table-responsive" v-if="optouts.length">
        <table class="table table-infos">
          <thead>
            <tr>
              <!-- <th scope="col">
                <span class="checkbox-wrapper"
                  ><div class="control-group opt-2">
                    <label class="control control-checkbox"
                      ><input type="checkbox" />
                      <div class="control_indicator"></div
                    ></label>
                  </div>
                  {{ $tc('app.name', 1) }}</span
                >
              </th> -->
              <th class="checkbox-wrapper" scope="col">
                <div class="control-group opt-2">
                  <label class="control control-checkbox" for="customCheckTAllOpted"
                      ><input id="customCheckTAllOpted" v-model="selectAllTemplates" type="checkbox" />
                    <div class="control_indicator"></div>
                  </label>
                </div>
                {{ $tc('generic-str.lbl-number', 1) }}
              </th>
              <th scope="col">
                {{ $t('generic-str.date') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(optout, i) in optouts" :key="optout.id">
              <!-- <td>
                <span class="img-wrapper">
                  <div class="control-group opt-2">
                    <label class="control control-checkbox"
                      ><input type="checkbox" />
                      <div class="control_indicator"></div
                    ></label>
                  </div>
                  <div class="image">R</div>
                  <span>Rodrigo Silva</span>
                </span>
              </td> -->
              <td>
                <span class="checkbox-wrapper">
                  <div class="control-group opt-2">
                    <label class="control control-checkbox" :for="'optout-check' + i">
                      <input
                      v-model="selectedOpted"
                      :id="'optout-check' + i"
                      :value="optout" type="checkbox" />
                      <div class="control_indicator"></div
                    ></label>
                  </div>
                  <span>+{{ optout.mobile_number }}</span>
                </span>
              </td>
              <td>
                <span>
                  <span>{{ optout.created_at | formatDate }}</span>
                </span>
              </td>
              <td>
                <dropdown>
                  <template v-slot:text>
                    <span class="material-symbols-outlined title">
                      more_vert
                    </span>
                  </template>
                  <template #items>
                    <!-- Remover -->
                    <button
                      class="dropdown-item"
                      @click="remove(optout)"
                    >
                      {{ $t('generic-str.remove') }}
                    </button>
                  </template>
                </dropdown>
                <!-- <div class="dropdown">
                  <a role="button" aria-haspopup="true" aria-expanded="false"
                    ><span class="material-symbols-outlined title">
                      more_vert
                    </span></a
                  >
                  <div class="dropdown-menu" style="">
                    <a class="dropdown-item"> Edit </a
                    ><button class="dropdown-item">Remove</button>
                  </div>
                </div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div v-else class="qt-block-ui relative" style="padding: 120px" /> -->
    <div class="loading min-h-300" v-else>
      <LoadingAnim />
    </div>
    <div class="card-footer">
      <div class="container-fluid">
        <div class="row">
          <pagination :lastPage="pages" @change="fetch" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import SmsService from '@/services/sms.service';
import Pagination from '@/components/rebranding/Pagination.vue';
import Dropdown from '@/components/Dropdown.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import KeywordsCard from '@/components/rebranding/rcs/optouts/KeywordsCard.vue';

export default {
  name: 'OptedCard',
  components: {
    Pagination,
    Dropdown,
    LottieAnimNoData,
    LoadingAnim,
    KeywordsCard,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      paginate: {},
      optouts: [],
      selectedOpted: [],
      form: {
        page: 1,
        channel: 'rcs',
      },
      pages: 1,
    };
  },
  computed: {
    selectAllTemplates: {
      get() {
        if (this.optouts) {
          return this.selectedOpted.length === this.optouts.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.optouts.forEach((optout) => {
            selected.push(optout);
          });
        }

        this.selectedOpted = selected;
      },
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.fetched = false;
      this.form.page = page;
      this.form.channel = 'rcs';
      SmsService.fetchOptouts(this.form).then(
        (response) => {
          this.fetched = true;
          this.optouts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(opted) {
      Swal.fire({
        title: `Remover número "${opted.mobile_number}"`,
        text: 'Essa ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          SmsService.deleteOptout(opted.id).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.fetch();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Número removido com sucesso!',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteMultiple() {
      Swal.fire({
        title: this.$t('warnings.optouts.remove-number'),
        text: this.$t('warnings.optouts.text-number'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedOpted.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteAllOptouts({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('warnings.optouts.removed-number'),
                type: 'success',
              });
              this.fetch();
              this.isSending = false;
              this.selectedOpted = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-responsive {
  min-height: 300px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.table-placeholder {
  margin-top: 25px;
}
.table-placeholder h5 {
  color: black !important;
}
.table-placeholder p {
  font-size: 13px;
}
.no-item {
  font-size: 60px;
}

/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}

.darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.header_options .input-group > input,
.header_options .input-group button {
  height: 50px;
}

.rebranding .add_person {
  cursor: pointer;
}

.rebranding .opts_card button {
  height: fit-content;
  padding: 0;
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: .7rem;
}

.table.table-infos td, .table.table-infos th, .table.table-infos thead th {
  border-bottom: unset;
}
.rebranding .dropdown .dropdown-menu:not(.rebranding .dropdown.dropup .dropdown-menu) {
  top: unset !important;
}

::v-deep .keyword-dropdown > button {
  height: fit-content;
  display: flex;
  align-items: center;
}

::v-deep .keyword-dropdown ul.dropdown-menu.show {
  width: 590px;
  // top: 120px !important;
  z-index: 5;
}

.keyword-title {
  font-size: 26px;
  font-weight: 400;
}
</style>
