<template>
  <div class="keyword-wrapper">
    <div class="col-lg-12 p-0">
      <div class="card">
        <div class="card-header header_options">
          <div class="card-title header_title">
            {{ $t('generic-str.key-word') }}
          </div>
          <!-- <div class="form-group col-12 m-auto mb-0">
            <div class="input-group">
              <input
                placeholder=""
                required="required"
                type="password"
                class="form-control"
              />
              <div class="input-group-prepend">
                <button type="button" class="btn icon_btn m-0">
                  <span class="material-symbols-outlined purple-add"> add </span>
                </button>
              </div>
            </div>
          </div> -->
          <div class="opts_card">
            <!-- <button class="btn" v-modal="{ target: 'create-key-modal' }">
              <span class="material-icons add_person"> add </span>
            </button> -->
            <button
              class="btn"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              @click="deleteMultiple"
              v-bind:disabled="selectedKeywords.length == 0 || isSending"
            >
              <span class="material-symbols-outlined title">delete</span>
            </button>
            <!-- <span class="material-icons-outlined title"> file_upload </span>
            <i class="fas fa-file-export title"></i> -->
          </div>
        </div>
        <div v-if="!keywords.length && fetched" class="card-body">
          <LottieAnimNoData />
          <h5 class="card-title m-t-20">Nenhuma palavra chave cadastrada</h5>
        </div>
        <div class="card-body" v-if="fetched">
          <div class="mb-4">
            <button
              type="button"
              class="btn p-0 icon_btn m-0 mr-2"
              v-modal="{ target: 'create-key-modal' }"
            >
              <span class="material-symbols-outlined purple-add"> add </span>
            </button>
            <span class="text-yup" style="font-weight: 400"
              >{{ $t('generic-str.add') }}
              {{ $t('generic-str.key-word') }}</span
            >
          </div>
          <div v-if="keywords.length" class="keywords-wrapper">
            <ul class="list-unstyled mb-3 list-options">
              <li class="mb-4">
                <div class="control-group opt-2">
                  <label
                    class="control control-checkbox"
                    for="check-all-keywords"
                    ><input
                      id="check-all-keywords"
                      v-model="selectAllKeywords"
                      type="checkbox"
                    />
                    <div class="control_indicator"></div>
                    <span>{{ $tc('generic-str.name', 1) }}</span></label
                  >
                </div>
              </li>
            </ul>
            <ul
              v-for="(keyword, i) in keywords"
              :key="keyword.id"
              class="list-unstyled mb-3 list-options"
            >
              <li>
                <div class="control-group opt-2">
                  <label
                    class="control control-checkbox"
                    :for="'keyword-check' + i"
                  >
                    <input
                      v-model="selectedKeywords"
                      :id="'keyword-check' + i"
                      :value="keyword"
                      type="checkbox"
                    />
                    <div class="control_indicator"></div>
                    <span>{{ keyword.keyword }}</span>
                  </label>
                  <dropdown>
                    <template v-slot:text>
                      <span class="material-symbols-outlined title" style=""
                        >more_vert</span
                      >
                    </template>
                    <template #items>
                      <!-- Editar -->
                      <button
                        class="dropdown-item"
                        v-modal="{ target: 'key-modal', data: keyword }"
                      >
                        {{ $t('generic-str.edit') }}
                      </button>
                      <!-- Remover -->
                      <button class="dropdown-item" @click="remove(keyword)">
                        {{ $t('generic-str.remove') }}
                      </button>
                    </template>
                  </dropdown>
                </div>
              </li>
            </ul>
          </div>
          <pagination :lastPage="pages" @change="fetch" />
        </div>
        <!-- <div v-else class="qt-block-ui relative" style="padding: 120px" /> -->
        <div class="loading min-h-300" v-else>
          <LoadingAnim />
        </div>
      </div>
    </div>
    <div class="col-lg-12 p-0">
      <div class="card">
        <!-- Msg Optout -->
        <div class="card-header header_options">
          <div class="card-title header_title">
            {{ $t('warnings.optouts.default') }}
          </div>
          <div class="col-lg-12">
            <button type="button" class="btn p-0 icon_btn m-0 mr-2">
              <span class="material-symbols-outlined purple-add"> add </span>
            </button>
            <span class="text-yup" style="font-weight: 400"
              >{{ $t('generic-str.add') }} {{ $tc('app.message', 1) }}</span
            >
          </div>
          <!-- <div class="opts_card">
                    <span class="material-icons add_person"> person_add </span>
                    <span class="material-icons-outlined title"> file_upload </span>
                    <i class="fas fa-file-export title"></i>
                  </div> -->
        </div>
        <div class="card-body msg-optout">
          <div class="col-lg">
            <textarea class="form-control" rows="1" />
          </div>
        </div>
        <!--/Msg Optout -->
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import SmsService from '@/services/sms.service';
import Pagination from '@/components/rebranding/Pagination.vue';
import Dropdown from '@/components/Dropdown.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'KeywordsTable',
  components: {
    Pagination,
    Dropdown,
    LottieAnimNoData,
    LoadingAnim,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      paginate: {},
      keywords: [],
      selectedKeywords: [],
      form: {
        page: 1,
        channel: 'rcs',
      },
      pages: 1,
    };
  },
  computed: {
    selectAllKeywords: {
      get() {
        if (this.keywords) {
          return this.selectedKeywords.length === this.keywords.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.keywords.forEach((keyword) => {
            selected.push(keyword);
          });
        }

        this.selectedKeywords = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.$root.$on('KeyWordsReload', () => {
      // your code goes here
      this.fetch();
    });
  },
  methods: {
    fetch(page = 1) {
      this.fetched = false;
      this.$store
        .dispatch('fetchRcsKeywords', {
          page,
        })
        .then((response) => {
          this.keywords = response.data;
          this.pages = response.last_page;
        })
        .finally(() => {
          this.fetched = true;
        });
    },
    remove(keyword) {
      Swal.fire({
        title: `Remover palavra-chave "${keyword.word}"`,
        text: 'Essa ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          SmsService.deleteKeyword(keyword.id).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.fetch();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Palavra-chave removida com sucesso!',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteMultiple() {
      Swal.fire({
        title: 'Remover palavras-chave?',
        text: 'Essa ação não poderá ser desfeita?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedKeywords.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteMultipleKeywords({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('warnings.optouts.removed-number'),
                type: 'success',
              });
              this.fetch();
              this.isSending = false;
              this.selectedKeywords = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 768px) and (max-width: 992px) {
  .btn-trim {
    display: none;
  }
}
.card-body {
  min-height: 200px !important;
}
.table-responsive {
  min-height: 300px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.table-placeholder {
  margin-top: 25px;
}
.table-placeholder h5 {
  color: black !important;
}
.table-placeholder p {
  font-size: 13px;
}
.no-item {
  font-size: 60px;
}
/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.header_options .input-group > input,
.header_options .input-group button {
  height: 50px;
}

.rebranding .add_person {
  cursor: pointer;
}

.rebranding .opts_card button {
  height: fit-content;
  padding: 0;
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

/* Keyword */
.list-options li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.list-options li .control-group {
  display: flex;
  gap: 10px;
  clear: both;
  width: 100%;
}

.list-options li .control-group .control {
  width: 100%;
}

.msg-optout {
  .control {
    display: flex;
    align-items: center;
  }

  .list-options span {
    font-weight: 400;
  }

  .list-options li .control-group .control {
    width: auto;
  }
}

.list-options li .control-group .control_indicator {
  float: left;
  margin-right: 10px;
}

.rebranding .purple-add {
  background-color: var(--clr-yup-purple);
  color: #fff !important;
  transition: 0.5s;
}

.rebranding .purple-add:hover {
  background-color: var(--clr-yup-purple-hover);
}

.rebranding .card {
  height: calc(100% - 1.875rem);
}

.keyword-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  textarea {
    resize: none;
  }
}

.rebranding .card .card-header .header_title,
.rebranding .header_title {
  font-weight: 400;
}
</style>
